<template>
  <el-row v-loading="isLoading">
    <div style="position: fixed;width: 395px;height: 100%;" :style="cardMarginTop">
      <div class="grid-content bg-purple">
        <div class="pic member_card" :style="{ background: dialogImageUrl2 ? 'url('+ dialogImageUrl2 +') no-repeat' : bgColorGradient, backgroundSize: '100% 100%' }">
          <div class="logo_area">
            <span class="vip_logo">
                <img id="js_logo_url_preview" :src="dialogImageUrl">
            </span>
            <span id="js_brand_name_preview" style="position: absolute;top: 20px;left: 70px;">
                {{ruleForm.vcard_name}}<!--TODO 获取当前登录用户的商户名 或 商盟时，选择一个商户-->
            </span><br/>
            <p id="js_title_preview" class="card_name" style="position: absolute;top: 30px;left: 70px;">
              {{ruleForm.vcard_title}}
            </p>
            <a href="javascript:;"><span class="qrcode" style="display: block;"></span></a>
            <span class="dis_card_code">
              <el-image
                      style="width: 40px; height: 40px"
                      :src="ruleForm.qrcode_url"
                      fit="cover"
              ></el-image>
            </span>
          </div>
        </div>
        <div class="msg_card_cell member_extend_area" :style="{ width: ruleForm.vcard_column.length === 2 ? '57%' : '95%' }">
          <li class="demo_0" v-if="ruleForm.vcard_column.indexOf('1') > -1">
            <a href="javascript:;">
              <span>积分</span>
              <p class="js_title_color_preview">
                0
              </p>
            </a>
          </li>
          <li class="demo_1" v-if="ruleForm.vcard_column.indexOf('2') > -1">
            <a href="javascript:;"><span>余额</span>
              <p class="js_title_color_preview">
                0.00元
              </p>
            </a>
          </li>
          <li class="demo_2" v-if="ruleForm.vcard_column.indexOf('3') > -1">
            <a href="javascript:;"><span>优惠券</span>
              <p class="js_title_color_preview">
                查看
              </p></a>
          </li>
          <li class="demo_3" v-if="ruleForm.vcard_column.indexOf('4') > -1">
            <a href="javascript:;"><span>等级</span>
              <p class="js_title_color_preview">
                查看
              </p></a>
          </li>
        </div>
        <div v-if="ruleForm.vcard_type == 'CODE_TYPE_BARCODE'" class="discode">
          <el-image
                  style="width: 250px; height: 60px"
                  :src="ruleForm.barcode_url"
                  fit="cover"
          ></el-image>
          <span style="margin-top: 20px; margin-bottom: 20px">
                888 8888 8888 8888
              </span>
        </div>
        <div v-else-if="ruleForm.vcard_type == 'CODE_TYPE_QRCODE'"
             class="discode">
          <el-image
                  style="width: 100px; height: 100px"
                  :src="ruleForm.qrcode_url"
                  fit="cover"
          ></el-image>
          <span style="margin-top: 10px; margin-bottom: 10px"></span>
        </div>
        <div class="center-btn">{{ruleForm.centerBtn}}</div>
        <div class="others">
          <!-- <div class="card_infos">
            <span>继续洗车</span>
            <span><i class="el-icon-arrow-right"></i></span>
          </div> -->
          <div class="card_infos">
            <span>
              {{ruleForm.is_custom_one === '1' || !ruleForm.entrance_name_one ? '自定义入口一(选填)' : ruleForm.entrance_name_one}}
            </span>
            <span class="card_right">
              {{ruleForm.is_custom_one === '1' ? '' : ruleForm.guidance_one}}&nbsp;&nbsp;&nbsp;
              <i class="el-icon-arrow-right"></i>
            </span>
          </div>
          <div class="card_infos">
            <span>
              {{ruleForm.is_custom_two === '1' || !ruleForm.entrance_name_two ? '自定义入口二(选填)' : ruleForm.entrance_name_two}}
            </span>
              <span class="card_right">
              {{ruleForm.is_custom_two === '1' ? '' : ruleForm.guidance_two}}&nbsp;&nbsp;&nbsp;
              <i class="el-icon-arrow-right"></i>
            </span>
          </div>
          <div class="card_infos">
            <span>
              {{ruleForm.is_custom_three === '1' || !ruleForm.entrance_name_three ? '自定义入口三(选填)' : ruleForm.entrance_name_three}}
            </span>
              <span class="card_right">
              {{ruleForm.is_custom_three === '1' ? '' : ruleForm.guidance_three}}&nbsp;&nbsp;&nbsp;
              <i class="el-icon-arrow-right"></i>
            </span>
          </div>
          <div class="card_infos">
            <span>
              {{ruleForm.is_custom_four === '1' || !ruleForm.entrance_name_four ? '自定义入口四(选填)' : ruleForm.entrance_name_four}}
            </span>
            <span class="card_right">
              {{ruleForm.is_custom_four === '1' ? '' : ruleForm.guidance_four}}&nbsp;&nbsp;&nbsp;
              <i class="el-icon-arrow-right"></i>
            </span>
          </div>
           <div class="card_infos">
            <span>会员卡详情</span>
            <span class="card_right"><i class="el-icon-arrow-right"></i></span>
          </div>
          <div class="card_infos">
            <span>公众号</span>
            <span class="card_right"><i class="el-icon-arrow-right"></i></span>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-left: 410px;">
      <div class="grid-content bg-purple-light vip-form" style="padding-top: 10px;">
        <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="110px"
                class="demo-ruleForm"
        >
          <div>
            <h3>基础设置</h3>
            <el-divider></el-divider>
            <el-form-item label="商家：" prop="shanghu">
              <el-select v-model="ruleForm.shanghu" :disabled="!isAdd" placeholder="请选择商户">
                <el-option
                  v-for="item in merchants"
                  :key="item.meId"
                  :label="item.meName"
                  :value="item.meId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="卡标题：" prop="vcard_name">
              <el-input class="createTimeInputWidth" v-model="ruleForm.vcard_name" placeholder="请输入会员卡标题" maxlength="12" show-word-limit ></el-input>
            </el-form-item>
            <el-form-item label="副标题：" prop="vcard_title">
              <el-input class="createTimeInputWidth" v-model="ruleForm.vcard_title" placeholder="卡副标题文案，例如：微信会员卡" maxlength="9" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="logo：" prop="vcard_log">
              <!-- auto-upload为是否自动加载；action为图片要上传到的地址，这里随便填一个，因为暂时不用 -->
              <!-- class为动态样式选择，是实现上传图片后上传框消失的关键 -->
              <el-upload action="/api/common/upload"
                         list-type="picture-card"
                         :on-preview="handlePictureCardPreview"
                         :on-remove="handleRemove"
                         :auto-upload="false"
                         :on-change="handleChange"
                         :class="objClass"
                         :file-list="fileList" :limit="1">
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color: #999;font-size: 12px;">
                尺寸120px*120px，支持jpg、jpeg、png图片，文件小于1M
              </span>
              <!-- el-dialog为点击预览图的放大按钮后弹出来的框，在框内显示放大的图片 -->
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>
            <el-form-item label="背景图：" prop="vcard_back_img">
              <!-- auto-upload为是否自动加载；action为图片要上传到的地址，这里随便填一个，因为暂时不用 -->
              <!-- class为动态样式选择，是实现上传图片后上传框消失的关键 -->
              <el-upload action="#"
                         list-type="picture-card"
                         :on-preview="handlePictureCardPreview2"
                         :on-remove="handleRemove2"
                         :auto-upload="false"
                         :on-change="handleChange2"
                         :class="objClass2"
                         :file-list="fileList2" :limit="1">
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color: #999;font-size: 12px;"><!--TODO 二选一校验-->
                背景图和颜色二选一，建议尺寸1080px*675px，选择与卡内容相关的图片，文件需要小于2M，支持jpg、jpeg、png图片
              </span>
              <!-- el-dialog为点击预览图的放大按钮后弹出来的框，在框内显示放大的图片 -->
              <el-dialog :visible.sync="dialogVisible2">
                <img width="100%" :src="dialogImageUrl2" alt="">
              </el-dialog>
            </el-form-item>
            <el-form-item label="会员卡颜色：" prop="vcard_back_color">
              <!--<el-color-picker
                      v-model="ruleForm.vcard_back_color"
                      :show-alpha="false"
                      @change="bgColorChange"
                      :predefine="predefineColors">
              </el-color-picker>-->
              <span class="tim-select-color-radio">
                <el-radio-group v-model="ruleForm.vcard_back_color" @change="bgColorChange">
                  <el-radio class="color010" label="Color010"><span></span></el-radio>
                  <el-radio class="color020" label="Color020"><span></span></el-radio>
                  <el-radio class="color030" label="Color030"><span></span></el-radio>
                  <el-radio class="color040" label="Color040"><span></span></el-radio>
                  <el-radio class="color050" label="Color050"><span></span></el-radio>
                  <el-radio class="color060" label="Color060"><span></span></el-radio>
                  <el-radio class="color070" label="Color070"><span></span></el-radio>
                  <el-radio class="color080" label="Color080"><span></span></el-radio>
                  <el-radio class="color081" label="Color081"><span></span></el-radio>
                  <el-radio class="color082" label="Color082"><span></span></el-radio>
                  <el-radio class="color090" label="Color090"><span></span></el-radio>
                  <el-radio class="color100" label="Color100"><span></span></el-radio>
                  <el-radio class="color101" label="Color101"><span></span></el-radio>
                  <el-radio class="color102" label="Color102"><span></span></el-radio>
                </el-radio-group>
              </span>
            </el-form-item>
            <!--目前只显示积分-->
            <el-form-item label="栏位展示：" prop="vcard_column">
              <el-checkbox-group v-model="ruleForm.vcard_column" :max="3">
                <el-checkbox label="1" disabled>积分</el-checkbox>
                <el-checkbox label="2">余额</el-checkbox>
                <el-checkbox label="3">优惠券</el-checkbox>
                <el-checkbox label="4">等级</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="条码展示：" prop="vcard_type">
              <div class="radio_type_group">
                <div class="radio-ctrl">
                  <span class="radio-s"
                  ><el-radio
                          v-model="ruleForm.vcard_type"
                          label="CODE_TYPE_ONLY_BARCODE"
                  >条形码</el-radio
                  ></span
                  >
                  <el-image
                          style="width: 60px; height: 50px"
                          :src="ruleForm.barcode_url"
                          fit="cover"
                  ></el-image>
                </div>
                <div class="radio-ctrl">
                  <span class="radio-s"
                  ><el-radio
                          v-model="ruleForm.vcard_type"
                          label="CODE_TYPE_ONLY_QRCODE"
                  >二维码</el-radio
                  ></span
                  >
                  <el-image
                          style="width: 50px; height: 50px; margin-left: 15px"
                          :src="ruleForm.qrcode_url"
                          fit="cover"
                  ></el-image>
                </div>
                <div class="radio-ctrl">
                  <span class="radio-s"
                  ><el-radio
                          v-model="ruleForm.vcard_type"
                          label="CODE_TYPE_BARCODE"
                  >卡面条码</el-radio
                  ></span
                  >
                  <el-image
                          style="width: 60px; height: 50px; margin-left: 20px"
                          :src="ruleForm.barcode_url"
                          fit="cover"
                  ></el-image>
                </div>
                <div class="radio-ctrl">
                  <span class="radio-s"
                  ><el-radio
                          v-model="ruleForm.vcard_type"
                          label="CODE_TYPE_QRCODE"
                  >卡面二维码</el-radio
                  ></span
                  >
                  <el-image
                          style="width: 50px; height: 50px; margin-left: 25px"
                          :src="ruleForm.qrcode_url"
                          fit="cover"
                  ></el-image>
                </div>
                <div class="radio-ctrl">
                  <span class="radio-s"
                  ><el-radio
                          v-model="ruleForm.vcard_type"
                          label="CODE_TYPE_NONE"
                  >不展示</el-radio
                  ></span
                  >
                </div>
              </div>
              <!-- <el-radio-group v-model="ruleForm.vcard_type">
                <el-radio label="CODE_TYPE_ONLY_BARCODE">条形码</el-radio>
                <el-radio label="CODE_TYPE_ONLY_QRCODE">二维码</el-radio>
                <el-radio label="CODE_TYPE_BARCODE">卡面条码</el-radio>
                <el-radio label="CODE_TYPE_QRCODE">卡面二维码</el-radio>
                <el-radio label="CODE_TYPE_TEXT">文本</el-radio>
                <el-radio label="CODE_TYPE_NONE">不显示任何码型</el-radio>
              </el-radio-group> -->
            </el-form-item>
            <el-form-item label="有效期：" prop="vcard_vil"><!--如果后期需要加其他类型，则需要测试-->
              <el-radio-group v-model="ruleForm.vcard_vil">
                <el-radio label="DATE_TYPE_PERMANENT">永久有效</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="可用时段：" prop="vcard_avi_time"><!--如果后期需要加其他类型，则需要测试-->
              <el-radio-group v-model="ruleForm.vcard_avi_time">
                <el-radio label="all_time">全部时段</el-radio>
              </el-radio-group>
            </el-form-item>
            <!--<el-form-item label="折扣优惠：" prop="vcard_disc">&lt;!&ndash;如果后期需要加其他类型，则需要测试&ndash;&gt;
              <el-input-number v-model="ruleForm.vcard_disc" :disabled="true" class="createTimeInputWidth"></el-input-number>
              <div style="color: #999;font-size: 12px;">
                该会员卡享受的折扣优惠,填10就是九折
              </div>
            </el-form-item>-->
          </div>
          <div>
            <h3>经验积分</h3>
            <el-divider></el-divider>
            <el-form-item label="开卡积分：" prop="vcard_init_bonus"><!--对应init_bonus-->
              <el-input placeholder="请输入开卡积分" oninput ="value=value.replace(/[^\d]/g,'')" v-model="ruleForm.vcard_init_bonus" style="width: 300px;">
                <template slot="prepend">开卡赠送</template>
                <template slot="append">积分/经验值</template>
              </el-input>
            </el-form-item>
            <!--<el-form-item label="卡券积分：">&lt;!&ndash;TODO 微信api里没找到相关字段，只有消费送积分&ndash;&gt;
              <el-input oninput ="value=value.replace(/[^\d]/g,'')" v-model="ruleForm.vcard_ticket_consume" style="width: 300px;">
                <template slot="prepend">每消费</template>
                <template slot="append">张卡券</template>
              </el-input>，
              <el-input oninput ="value=value.replace(/[^\d]/g,'')" v-model="ruleForm.vcard_ticket_give" style="width: 300px;">
                <template slot="prepend">赠送</template>
                <template slot="append">积分/经验值</template>
              </el-input>
            </el-form-item>-->
          </div>
           <div>
            <h3>开卡赠送</h3>
            <el-divider></el-divider>
            <el-form-item label="开卡有礼：" prop="vcard_send_type">
               <el-radio-group v-model="ruleForm.vcard_send_type" @change="getCoupons">
                  <el-radio :label="0">自定义链接</el-radio>
                  <el-radio :label="1">赠送卡券</el-radio>
                </el-radio-group>
            </el-form-item>
            <div v-if="ruleForm.vcard_send_type === 0">
              <el-form-item label="自定义链接：" prop="vcard_send_url">
                <el-input placeholder="请输入自定义链接" maxlength="255"
                          show-word-limit v-model="ruleForm.vcard_send_url" />
              </el-form-item>
            </div>
             <div v-if="ruleForm.vcard_send_type === 1">
              <el-form-item label="选择卡券：" prop="vcard_send_coupons">
                <div>
                  <el-checkbox  v-model="coupon" :label="0">赠送优惠券</el-checkbox>
                  <template v-if="coupon">
                    <div class="col-md-6  coupon-add charge-rule-box" style="float:none;margin-top:10px;margin-left: -22px;width: 600px!important;">
                      <div v-for="(item,index) in arrayGiftData" :key="index" style="margin-top:15px;display: flex;align-items: center">
                        <span style="margin-left: 15px;">送</span> &nbsp;
                          <el-select
                                v-model="item.type" @change="changeGiftType(index, item)">
                            <el-option
                                    v-for="item in arrayGiftData.giftOptions"
                                    :key="item.cou_id"
                                    :label="item.cou_title+'(剩余'+item.surplus+'数量)'"
                                    :value="item.cou_id"
                                    :disabled="item.disabled"
                                    @click.native="handleGiftChange(item)"
                            >
                            </el-option>
                          </el-select>
                          <el-input-number v-model="item.recSurplus" :min="1"></el-input-number> 张
                          <i class="el-icon-remove-outline" style="color: red;font-size: 20px;margin: 0 5px;" @click="deletesGift(item,index)"></i>
                      </div>
                      <span style="margin-left: 15px;color: #3D7FFF;cursor:pointer;" @click="addGift" @change="$forceUpdate()">+ 添加</span>
                    </div>
                  </template>
                </div>
                <div>
                  <el-checkbox v-model="timesCard" :label="1">赠送计次卡</el-checkbox>
                  <template v-if="timesCard">
                    <div class="col-md-6  coupon-add charge-rule-box" style="float:none;margin-top:10px;margin-left: -22px;width: 600px!important;">
                      <div v-for="(item,index) in arrayTcountData" :key="index" style="margin-top:15px;display: flex;align-items: center">
                        <span style="margin-left: 15px;">送</span> &nbsp;
                          <el-select
                                v-model="item.type" @change="changeTypeTcount(index, item)">
                            <el-option
                                    v-for="item2 in arrayTcountData.tcountOptions"
                                    :key="item2.cou_id"
                                    :label="item2.cou_title+'(剩余'+item2.surplus+'数量)'"
                                    :value="item2.cou_id"
                                    :disabled="item2.disabled"
                                    @click.native="tcountChange(item2)"
                            >
                            </el-option>
                          </el-select>
                          <el-input-number v-model="item.recSurplus" :min="0"></el-input-number> 张
                          <i class="el-icon-remove-outline" style="color: red;font-size: 20px;margin: 0 5px;" @click="deletesTcount(item,index)"></i>
                      </div>
                      <span style="margin-left: 15px;color: #3D7FFF;cursor:pointer;" @click="addTcount" @change="$forceUpdate()">+ 添加</span>
                    </div>

                  </template>
                </div>
              </el-form-item>
            </div>
          </div>
          <div style="margin-top: 50px;">
            <h3>会员卡详情</h3>
            <el-divider></el-divider>
            <el-form-item label="特权说明：" prop="vcard_in_part"><!--对应prerogative-->
              <el-input class="createTimeInputWidth" placeholder="请输入会员卡特权说明" type="textarea" maxlength="1024"
                        show-word-limit :autosize="{ minRows: 5, maxRows: 10 }" v-model="ruleForm.vcard_in_part" />
            </el-form-item>
            <el-form-item label="使用须知：" prop="vcard_use">
              <el-input placeholder="请输入使用须知" v-model="ruleForm.vcard_use" class="createTimeInputWidth"
                        maxlength="100"
                        show-word-limit />
              <!--<el-input placeholder="请输入使用须知" v-model="ruleForm.vcard_use" :disabled="true" />-->
            </el-form-item>
          </div>
          <div style="margin-top: 50px;">
            <h3>商户介绍</h3>
            <el-divider></el-divider>
            <el-form-item label="客服电话：" prop="vcard_phone"><!--对应 service_phone-->
              <el-input class="createTimeInputWidth" placeholder="请输入客服电话" maxlength="24" v-model="ruleForm.vcard_phone" />
            </el-form-item>
            <el-form-item label="商户服务：" prop="vcard_service"><!--对应business_service-->
              <el-checkbox-group v-model="ruleForm.vcard_service">
                <el-checkbox label="BIZ_SERVICE_DELIVER" border>外卖服务</el-checkbox>
                <el-checkbox label="BIZ_SERVICE_FREE_PARK" border>停车位</el-checkbox>
                <el-checkbox label="BIZ_SERVICE_WITH_PET" border>可带宠物</el-checkbox>
                <el-checkbox label="BIZ_SERVICE_FREE_WIFI" border>免费wifi</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div style="margin-top: 50px;">
            <h3>开卡设置</h3>
            <el-divider></el-divider>
            <el-form-item label="开卡信息：" prop="vcard_common_field">
              <el-checkbox-group v-model="ruleForm.vcard_common_field">
                <el-checkbox label="USER_FORM_INFO_FLAG_MOBILE" disabled>手机号</el-checkbox>
                <el-switch class="kkxx_switch" active-color="#F56C6C" v-model="isRequired.phone" active-text="必填" disabled></el-switch><br/>
                <el-checkbox label="USER_FORM_INFO_FLAG_SEX">性别</el-checkbox>
                <el-switch class="kkxx_switch" active-color="#F56C6C" v-model="isRequired.sex" active-text="必填"></el-switch><br/>
                <el-checkbox label="USER_FORM_INFO_FLAG_NAME">姓名</el-checkbox>
                <el-switch class="kkxx_switch" active-color="#F56C6C" v-model="isRequired.name" active-text="必填"></el-switch><br/>
                <el-checkbox label="USER_FORM_INFO_FLAG_BIRTHDAY">生日</el-checkbox>
                <el-switch class="kkxx_switch" active-color="#F56C6C" v-model="isRequired.birthday" active-text="必填"></el-switch><br/>
                <el-checkbox label="USER_FORM_INFO_FLAG_IDCARD">身份证</el-checkbox>
                <el-switch class="kkxx_switch" active-color="#F56C6C" v-model="isRequired.idCard" active-text="必填"></el-switch><br/>
                <el-checkbox label="USER_FORM_INFO_FLAG_EMAIL">邮箱</el-checkbox>
                <el-switch class="kkxx_switch" active-color="#F56C6C" v-model="isRequired.email" active-text="必填"></el-switch><br/>
                <el-checkbox label="USER_FORM_INFO_FLAG_LOCATION">详细地址</el-checkbox>
                <el-switch class="kkxx_switch" active-color="#F56C6C" v-model="isRequired.address" active-text="必填"></el-switch><br/>
                <!-- <el-checkbox label="USER_FORM_INFO_FLAG_EDUCATION_BACKGRO">教育背景</el-checkbox>
                <el-switch class="kkxx_switch" active-color="#F56C6C" v-model="isRequired.education" active-text="必填"></el-switch><br/> -->
                <el-checkbox label="USER_FORM_INFO_FLAG_INDUSTRY">行业</el-checkbox>
                <el-switch class="kkxx_switch" active-color="#F56C6C" v-model="isRequired.industry" active-text="必填"></el-switch><br/>
                <el-checkbox label="USER_FORM_INFO_FLAG_INCOME">收入</el-checkbox>
                <el-switch class="kkxx_switch" active-color="#F56C6C" v-model="isRequired.income" active-text="必填"></el-switch><br/>
                <el-checkbox label="USER_FORM_INFO_FLAG_HABIT">兴趣爱好</el-checkbox>
                <el-switch class="kkxx_switch" active-color="#F56C6C" v-model="isRequired.interest" active-text="必填"></el-switch><br/>
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div style="margin-top: 50px;">
            <h3>使用设置</h3>
            <el-divider></el-divider>
            <!--<el-form-item label="" prop="vcard_is_share">
              <el-checkbox v-model="ruleForm.vcard_is_share">用户可以分享领券链接</el-checkbox>
            </el-form-item>
            <el-form-item label="" prop="vcard_is_activate">
              <el-checkbox-group v-model="ruleForm.vcard_is_activate">
                <el-checkbox label="Y">用户需激活后才能使用</el-checkbox>
              </el-checkbox-group>
            </el-form-item>-->
          </div>
          <el-form-item label="适用门店：" prop="symd"><!--未保存至数据库-->
            <el-radio-group v-model="ruleForm.symd">
              <el-radio label="Y">全部门店适用</el-radio>
            </el-radio-group>
          </el-form-item>
          <div style="margin-top: 50px;">
            <h3>自定义入口一</h3>
            <el-divider></el-divider>
            <el-form-item label="" prop="is_custom_one">
              <el-radio-group v-model="ruleForm.is_custom_one">
                <el-radio label="1">未设置</el-radio>
                <el-radio label="2">自定义链接</el-radio>
                <el-radio label="3">自定义小程序</el-radio>
              </el-radio-group>
            </el-form-item>
            <div v-if="ruleForm.is_custom_one === '2'">
              <el-form-item label="入口名称" prop="entrance_name_one">
                <el-input placeholder="请输入入口名称" maxlength="5"
                          show-word-limit v-model="ruleForm.entrance_name_one" />
              </el-form-item>
              <el-form-item label="自定义链接" prop="entrance_link_one">
                <el-input placeholder="请输入自定义链接" maxlength="128"
                          show-word-limit v-model="ruleForm.entrance_link_one" />
              </el-form-item>
              <el-form-item label="引导语" prop="guidance_one">
                <el-input placeholder="请输入引导语" maxlength="6"
                          show-word-limit v-model="ruleForm.guidance_one" />
              </el-form-item>
            </div>
            <div v-if="ruleForm.is_custom_one === '3'">
              <el-form-item label="入口名称" prop="entrance_name_one">
                <el-input placeholder="请输入入口名称" maxlength="5"
                          show-word-limit v-model="ruleForm.entrance_name_one" />
              </el-form-item>
              <el-form-item label="小程序原始ID" prop="program_id_one">
                <el-input placeholder="请输入小程序原始ID" maxlength="100" v-model="ruleForm.program_id_one" />
              </el-form-item>
              <el-form-item label="小程序页面" prop="program_page_one">
                <el-input placeholder="请输入小程序页面" maxlength="100" v-model="ruleForm.program_page_one" />
              </el-form-item>
              <el-form-item label="引导语" prop="guidance_one">
                <el-input placeholder="请输入引导语" maxlength="6"
                          show-word-limit v-model="ruleForm.guidance_one" />
              </el-form-item>
            </div>
          </div>
          <div style="margin-top: 50px;">
            <h3>自定义入口二</h3>
            <el-divider></el-divider>
            <el-form-item label="" prop="is_custom_two">
              <el-radio-group v-model="ruleForm.is_custom_two">
                <el-radio label="1">未设置</el-radio>
                <el-radio label="2">自定义链接</el-radio>
                <el-radio label="3">自定义小程序</el-radio>
              </el-radio-group>
            </el-form-item>
            <div v-if="ruleForm.is_custom_two === '2'">
              <el-form-item label="入口名称" prop="entrance_name_two">
                <el-input placeholder="请输入入口名称" maxlength="5"
                          show-word-limit v-model="ruleForm.entrance_name_two" />
              </el-form-item>
              <el-form-item label="自定义链接" prop="entrance_link_two">
                <el-input placeholder="请输入自定义链接" maxlength="128"
                          show-word-limit v-model="ruleForm.entrance_link_two" />
              </el-form-item>
              <el-form-item label="引导语" prop="guidance_two">
                <el-input placeholder="请输入引导语" maxlength="6"
                          show-word-limit v-model="ruleForm.guidance_two" />
              </el-form-item>
            </div>
            <div v-if="ruleForm.is_custom_two === '3'">
              <el-form-item label="入口名称" prop="entrance_name_two">
                <el-input placeholder="请输入入口名称" maxlength="5"
                          show-word-limit v-model="ruleForm.entrance_name_two" />
              </el-form-item>
              <el-form-item label="小程序原始ID" prop="program_id_two">
                <el-input placeholder="请输入小程序原始ID" maxlength="100" v-model="ruleForm.program_id_two" />
              </el-form-item>
              <el-form-item label="小程序页面" prop="program_page_two">
                <el-input placeholder="请输入小程序页面" maxlength="100" v-model="ruleForm.program_page_two" />
              </el-form-item>
              <el-form-item label="引导语" prop="guidance_two">
                <el-input placeholder="请输入引导语" maxlength="6"
                          show-word-limit v-model="ruleForm.guidance_two" />
              </el-form-item>
            </div>
          </div>
          <div style="margin-top: 50px;">
            <h3>自定义入口三</h3>
            <el-divider></el-divider>
            <el-form-item label="" prop="is_custom_three">
              <el-radio-group v-model="ruleForm.is_custom_three">
                <el-radio label="1">未设置</el-radio>
                <el-radio label="2">自定义链接</el-radio>
                <el-radio label="3">自定义小程序</el-radio>
              </el-radio-group>
            </el-form-item>
            <div v-if="ruleForm.is_custom_three === '2'">
              <el-form-item label="入口名称" prop="entrance_name_three">
                <el-input placeholder="请输入入口名称" maxlength="5"
                          show-word-limit v-model="ruleForm.entrance_name_three" />
              </el-form-item>
              <el-form-item label="自定义链接" prop="entrance_link_three">
                <el-input placeholder="请输入自定义链接" maxlength="128"
                          show-word-limit v-model="ruleForm.entrance_link_three" />
              </el-form-item>
              <el-form-item label="引导语" prop="guidance_three">
                <el-input placeholder="请输入引导语" maxlength="6"
                          show-word-limit v-model="ruleForm.guidance_three" />
              </el-form-item>
            </div>
            <div v-if="ruleForm.is_custom_three === '3'">
              <el-form-item label="入口名称" prop="entrance_name_three">
                <el-input placeholder="请输入入口名称" maxlength="5"
                          show-word-limit v-model="ruleForm.entrance_name_three" />
              </el-form-item>
              <el-form-item label="小程序原始ID" prop="program_id_three">
                <el-input placeholder="请输入小程序原始ID" maxlength="100" v-model="ruleForm.program_id_three" />
              </el-form-item>
              <el-form-item label="小程序页面" prop="program_page_three">
                <el-input placeholder="请输入小程序页面" maxlength="100" v-model="ruleForm.program_page_three" />
              </el-form-item>
              <el-form-item label="引导语" prop="guidance_three">
                <el-input placeholder="请输入引导语" maxlength="6"
                          show-word-limit v-model="ruleForm.guidance_three" />
              </el-form-item>
            </div>
          </div>
          <div style="margin-top: 50px;">
            <h3>自定义入口四</h3>
            <el-divider></el-divider>
            <el-form-item label="" prop="is_custom_four">
              <el-radio-group v-model="ruleForm.is_custom_four">
                <el-radio label="1">未设置</el-radio>
                <el-radio label="2">自定义链接</el-radio>
                <el-radio label="3">自定义小程序</el-radio>
              </el-radio-group>
            </el-form-item>
            <div v-if="ruleForm.is_custom_four === '2'">
              <el-form-item label="入口名称" prop="entrance_name_four">
                <el-input placeholder="请输入入口名称" maxlength="5"
                          show-word-limit v-model="ruleForm.entrance_name_four" />
              </el-form-item>
              <el-form-item label="自定义链接" prop="entrance_link_four">
                <el-input placeholder="请输入自定义链接" maxlength="128"
                          show-word-limit v-model="ruleForm.entrance_link_four" />
              </el-form-item>
              <el-form-item label="引导语" prop="guidance_four">
                <el-input placeholder="请输入引导语" maxlength="6"
                          show-word-limit v-model="ruleForm.guidance_four" />
              </el-form-item>
            </div>
            <div v-if="ruleForm.is_custom_four === '3'">
              <el-form-item label="入口名称" prop="entrance_name_four">
                <el-input placeholder="请输入入口名称" maxlength="5"
                          show-word-limit v-model="ruleForm.entrance_name_four" />
              </el-form-item>
              <el-form-item label="小程序原始ID" prop="program_id_four">
                <el-input placeholder="请输入小程序原始ID" maxlength="100" v-model="ruleForm.program_id_four" />
              </el-form-item>
              <el-form-item label="小程序页面" prop="program_page_four">
                <el-input placeholder="请输入小程序页面" maxlength="100" v-model="ruleForm.program_page_four" />
              </el-form-item>
              <el-form-item label="引导语" prop="guidance_four">
                <el-input placeholder="请输入引导语" maxlength="6"
                          show-word-limit v-model="ruleForm.guidance_four" />
              </el-form-item>
            </div>
          </div>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('ruleForm')">保存</el-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <el-button @click="() => { this.$router.go(-1) }">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-row>
</template>

<script>
  import api from "../../api/vipuser";
  import privateApi from "../../api/private";
  import config from "../../config/index";
  import merc from "../../api/merchant";
  import coups from '../../api/coupons'
  import qs from "qs";
export default {
  name: "Vcreate",
  data() {
    return {
      coupon:'',//优惠卷
      timesCard:'',//计次卡
      merchants:[],
      cardMarginTop: {},
      isAdd: true,
      isLoading: false,
      dialogVisible: false,
      fileList: [],
      objClass: {
        upLoadShow: true,
        upLoadHide: false,
      },
      dialogImageUrl: '', //图片回显
      dialogVisible2: false,
      fileList2: [],
      objClass2: {
        upLoadShow: true,
        upLoadHide: false,
      },
      dialogImageUrl2: '',
      isRequired: {
        phone: true,
        sex: false,
        name: false,
        birthday: false,
        idCard: false,
        email: false,
        address: false,
        education: false,
        industry: false,
        income: false,
        interest: false,
      },
      ruleForm: {
        editId: "",
        centerBtn:"立即洗车",
        shanghu: '',
        vcard_name: "",
        vcard_title: "",
        vcard_log: null,
        vcard_back_img: "",
        vcard_back_color: 'Color010',
        vcard_type: "",
        vcard_column: [],
        vcard_vil: 'DATE_TYPE_PERMANENT',
        vcard_avi_time: 'all_time',
        vcard_disc: '',//10是9折
        vcard_ticket_consume: "",
        vcard_ticket_give: "",
        vcard_in_part: "",
        vcard_service: [],
        vcard_common_field: ['USER_FORM_INFO_FLAG_MOBILE'],
        vcard_is_share: false,
        vcard_is_activate: false,
        symd: 'Y',
        vcard_init_bonus: '10',
        vcard_use: '先领取再使用',
        vcard_phone: '',
        is_custom_one: '1',
        is_custom_two: '1',
        is_custom_three: '1',
        is_custom_four: '1',
        entrance_name_one: '',
        entrance_link_one: '',
        guidance_one: '',
        program_id_one: '',
        program_page_one: '',
        entrance_name_two: '',
        entrance_link_two: '',
        guidance_two: '',
        program_id_two: '',
        program_page_two: '',
        entrance_name_three: '',
        entrance_link_three: '',
        guidance_three: '',
        program_id_three: '',
        program_page_three: '',
        entrance_name_four: '',
        entrance_link_four: '',
        guidance_four: '',
        program_id_four: '',
        program_page_four: '',
        barcode_url: require("@/assets/code_type2.jpg"),
        qrcode_url: require("@/assets/code_type1.jpg"),
        vcard_send_type:-1,
        vcard_send_url:'',
        vcard_send_coupons:[],
        vcard_send_coupons_options:[],
        searchShanghu:""
      },
      rules: {
        vcard_name: [
          { required: true, message: "请输入会员卡标题", trigger: "blur" },
        ],
        vcard_title: [
          { required: true, message: "请输入会员卡副标题", trigger: "blur" },
        ],
        vcard_log: [
          { required: true, message: "请选择logo图标", trigger: "change" },
        ],
        vcard_type: [
          { required: true, message: "请选择展示类型", trigger: "change" },
        ],
        // vcard_column: [
        //   { required: true, message: "请至少选择一个栏位", trigger: "change" },
        // ],
        vcard_vil: [
          { required: true, message: "请选择有效期", trigger: "change" },
        ],
        vcard_avi_time: [
          { required: true, message: "请选择可用时段", trigger: "change" },
        ],
        /*vcard_disc: [
          { required: true, message: "请填写折扣优惠", trigger: "change" },
        ],*/
        vcard_in_part: [
          { required: true, message: "请输入特权说明", trigger: "blur" },
        ],
        vcard_use: [
          { required: true, message: "请输入使用须知", trigger: "change" },
        ],
        vcard_common_field: [
          { required: true, message: "至少选择一项开卡信息", trigger: "change" },
        ],
        entrance_name_one: [
          { required: true, message: "请输入入口名称", trigger: "blur" },
        ],
        entrance_link_one: [
          { required: true, message: "请输入自定义链接", trigger: "blur" },
        ],
        guidance_one: [
          { required: false, message: "请输入引导语", trigger: "blur" },
        ],
        program_id_one: [
          { required: true, message: "请输入小程序原始ID", trigger: "blur" },
        ],
        program_page_one: [
          { required: true, message: "请输入小程序页面", trigger: "blur" },
        ],
        entrance_name_two: [
          { required: true, message: "请输入入口名称", trigger: "blur" },
        ],
        entrance_link_two: [
          { required: true, message: "请输入自定义链接", trigger: "blur" },
        ],
        guidance_two: [
          { required: false, message: "请输入引导语", trigger: "blur" },
        ],
        program_id_two: [
          { required: true, message: "请输入小程序原始ID", trigger: "blur" },
        ],
        program_page_two: [
          { required: true, message: "请输入小程序页面", trigger: "blur" },
        ],
        entrance_name_three: [
          { required: true, message: "请输入入口名称", trigger: "blur" },
        ],
        entrance_link_three: [
          { required: true, message: "请输入自定义链接", trigger: "blur" },
        ],
        guidance_three: [
          { required: false, message: "请输入引导语", trigger: "blur" },
        ],
        program_id_three: [
          { required: true, message: "请输入小程序原始ID", trigger: "blur" },
        ],
        program_page_three: [
          { required: true, message: "请输入小程序页面", trigger: "blur" },
        ],
        entrance_name_four: [
          { required: true, message: "请输入入口名称", trigger: "blur" },
        ],
        entrance_link_four: [
          { required: true, message: "请输入自定义链接", trigger: "blur" },
        ],
        guidance_four: [
          { required: false, message: "请输入引导语", trigger: "blur" },
        ],
        program_id_four: [
          { required: true, message: "请输入小程序原始ID", trigger: "blur" },
        ],
        program_page_four: [
          { required: true, message: "请输入小程序页面", trigger: "blur" },
        ],
        vcard_phone: [
          { required: true, message: "请输入客服电话", trigger: "blur" },
          {
            pattern: /^(\(\d{3,4}-)|(\d{3.4}-)?\d{3,8}$/,
            message: '请输入正确的电话号码',
          }
        ],
      },
      predefineColors: [
        '#63b359',
        '#2c9f67',
        '#509fc9',
        '#5885cf',
        '#9062c0',
        '#d09a45',
        '#e4b138',
        '#ee903c',
        '#a9d92d',
        '#dd6549',
        '#cc463d',
      ],
      bgColorGradient: '#63b359',
      arrayGiftData:[
        {
          giftOptions:[],//卡卷
          recSurplus:1,
          type: ''
        }
      ],
      arrayTcountData:[
        {
          tcountOptions:[],//计次卡
          recSurplus:1,
          type: ''
        }
      ],
    };
  },
  created() {
    this.getMerchants();
    if(this.$route.query.vcardId){
      this.isAdd = false;
      this.edit();
      this.ruleForm.shanghu=Number(this.$route.query.vcardMeId)
    } else {
      this.getListSyCoupons();
    }
  },

  watch: {
    $route(to, from){//监听地址栏变动，否则 点了修改，又点了菜单会出问题
      if(to.path === '/vip/vcard'){//从修改变为了新增
        this.isAdd = true;
        this.$refs["ruleForm" ].resetFields();
      }
    },
  },
  mounted(){
    // addEventListener 方法用于向指定元素添加事件
    // 参数1：必选 事件名 例如： click , scroll , mouseenter
    // 参数2：必选 指定事件触发时的执行函数
    // 参数3：可选 布尔值 指定事件是否咋在捕获或冒泡阶段执行
    // true - 事件在句柄捕获阶段执行
    // false - 事件句柄冒泡阶段执行
    window.addEventListener('scroll', this.scrollDs , true)
  },
  methods: {
    // getUserInfo() {
    //   userApi.userinfo().then(res => {
    //     console.log(res)
    //     this.ruleForm.vcard_phone = res.data.data.result.SJphone;
    //     this.ruleForm.shanghu=res.data.data.result.shangj
    //   });
    // },
    //获取当前商户下所有的卡券
    getCoupons(value){
      /*if(value===1){
        this.ruleForm.vcard_send_coupons_options=[]
        coups.getAllCoupons(this.ruleForm.shanghu?this.ruleForm.shanghu:this.ruleForm.searchShanghu).then(res=>{
          console.log(res)
          let times={}
          times.label="计次卡"
          let timesOptions=[]
          res.data.data.times.forEach(time => {
            let options={}
            options.value=time.cou_batch
            options.label=time.cou_title
            timesOptions.unshift(options)
          });
          times.options=timesOptions;

          let gifts={}
          gifts.label="代金券"
          let giftOptions=[]
          res.data.data.gifts.forEach(time => {
            let options={}
            options.value=time.cou_batch
            options.label=time.cou_title
            giftOptions.unshift(options)
          });
          gifts.options=giftOptions

          this.ruleForm.vcard_send_coupons_options.push(times)
          this.ruleForm.vcard_send_coupons_options.push(gifts)
        })
      }else{
        this.ruleForm.vcard_send_coupons=[]
      }*/

    },
    changeGiftType(index,item){
      this.arrayGiftData.giftOptions.forEach(v => {
        v.disabled = false
        for(var i = 0; i < this.arrayGiftData.length; i ++){
          if(this.arrayGiftData[i].type === v.cou_id){
            v.disabled = true
            v.recSurplus = this.arrayGiftData[i].recSurplus;
          }
        }
      });
    },
    handleGiftChange(item){
      this.arrayGiftData.recSurplus = item.rec_surplus;
    },
    tcountChange(item){
      this.arrayTcountData.recSurplus = item.rec_surplus;
    },
    deletesGift(item,index){
      if(this.arrayGiftData.length<=1){ ///如果只有一个框则不可以删除
        return false
      }
      this.arrayGiftData.splice(index,1) //删除数组中对应的数据也就是将这个位置的框删除
      this.arrayGiftData.giftOptions.forEach(v =>{
        if(v.cou_id === item.type && v.disabled){
          v.disabled = false
        }
      });
    },
    deletesTcount(item,index){
      if(this.arrayTcountData.length<=1){ ///如果只有一个框则不可以删除
        return false
      }
      this.arrayTcountData.splice(index,1) //删除数组中对应的数据也就是将这个位置的框删除
      this.arrayTcountData.tcountOptions.forEach(v =>{
        if(v.cou_id === item.type && v.disabled){
          v.disabled = false
        }
      });
    },
    changeTypeTcount(){
      this.arrayTcountData.tcountOptions.forEach(v => {
        v.disabled = false
        for(var i = 0; i < this.arrayTcountData.length; i ++){
          if(this.arrayTcountData[i].type === v.cou_id){
            v.disabled = true
          }
        }
      });
    },
    addGift(){
      this.arrayGiftData.push({
        giftOptions:[],
        recSurplus:1,
        type:''
      });
    },
    addTcount(){
      this.arrayTcountData.push({
        tcountOptions:[],
        recSurplus:1,
        type:''
      });
    },
    getListSyCoupons(){
      privateApi.getListSyCoupons(0).then(res =>{
        this.arrayGiftData.giftOptions = res.data.data.result;
      });
      privateApi.getListSyCoupons(1).then(res =>{
        this.arrayTcountData.tcountOptions = res.data.data.result;
      });
    },
    getMerchants(){
      merc.getMerchatns().then(res=>{
       this.merchants=res.data.data.merchants
      })
    },
    edit(){
      this.isLoading = true;
      // this.ruleForm.searchShanghu=this.merchants[0].meId;
      //
      api.getInfoByVcardId(this.$route.query.vcardId)
      .then((res) => {
        this.ruleForm.editId = this.$route.query.vcardId;
        let vcardInfo = res.data.data.result;
        this.ruleForm.searchShanghu=vcardInfo.vcardMeId
        this.ruleForm.vcard_name = vcardInfo.vcardName
        this.ruleForm.vcard_title = vcardInfo.vcardTitle
        if(vcardInfo.vcardLog){
          let vcardLogUrl = config.baseUrl + "/" + vcardInfo.vcardLog;
          this.fileList = [{ url: vcardLogUrl }];
          this.objClass.upLoadHide = true;//上传图片后置upLoadHide为真，隐藏上传框
          this.objClass.upLoadShow = false;
          this.dialogImageUrl = vcardLogUrl;
          this.ruleForm.vcard_log = "noEdit";
        }
        if(vcardInfo.vcardPic){
          let vcardBackImgUrl = config.baseUrl + "/" + vcardInfo.vcardPic;
          this.fileList2 = [{ url: vcardBackImgUrl }];
          this.objClass2.upLoadHide = true;//上传图片后置upLoadHide为真，隐藏上传框
          this.objClass2.upLoadShow = false;
          this.dialogImageUrl2 = vcardBackImgUrl;
          this.ruleForm.vcard_back_img = "noEdit";
        }
        if(vcardInfo.vcardColor) {
          this.ruleForm.vcard_back_color = vcardInfo.vcardColor;
          this.bgColorChange(vcardInfo.vcardColor);
        }
        this.ruleForm.vcard_type = vcardInfo.vcardType
        this.ruleForm.vcard_vil = vcardInfo.vcardVil
        this.ruleForm.vcard_avi_time = vcardInfo.vcardAviTime
        this.ruleForm.vcard_disc = vcardInfo.vcardDisc
        this.ruleForm.vcard_ticket_consume = vcardInfo.vcardTicketConsume
        this.ruleForm.vcard_ticket_give = vcardInfo.vcardTicketGive
        this.ruleForm.vcard_in_part = vcardInfo.vcardInPart
        this.ruleForm.vcard_service = vcardInfo.vcardService ? vcardInfo.vcardService.split(',') : []
        this.ruleForm.vcard_column = vcardInfo.vcardColumn ? vcardInfo.vcardColumn.split(',') : []
        let required_form = []
        let optional_form = []
        if(vcardInfo.requiredForm) required_form = vcardInfo.requiredForm.split(',')
        if(vcardInfo.optionalForm) optional_form = vcardInfo.optionalForm.split(',')

        this.ruleForm.vcard_common_field = [...required_form, ...optional_form]
        this.initFieldInfo(vcardInfo.requiredForm.split(','), true)
        this.initFieldInfo(vcardInfo.optionalForm.split(','), false)

        this.ruleForm.vcard_is_share = vcardInfo.vcardIsShare === "true"
        this.ruleForm.vcard_is_activate = vcardInfo.vcardIsActivate === "true"
        //this.ruleForm.symd = vcardInfo.symd
        this.ruleForm.vcard_init_bonus = vcardInfo.vcardInitBonus
        this.ruleForm.vcard_use = vcardInfo.vcardUse
        this.ruleForm.vcard_phone = vcardInfo.vcardPhone

        this.ruleForm.is_custom_one = vcardInfo.isCustomOne
        this.ruleForm.entrance_name_one = vcardInfo.entranceNameOne
        this.ruleForm.entrance_link_one = vcardInfo.entranceLinkOne
        this.ruleForm.guidance_one = vcardInfo.guidanceOne
        this.ruleForm.program_id_one = vcardInfo.programIdOne
        this.ruleForm.program_page_one = vcardInfo.programPageOne

        this.ruleForm.is_custom_two = vcardInfo.isCustomTwo
        this.ruleForm.entrance_name_two = vcardInfo.entranceNameTwo
        this.ruleForm.entrance_link_two = vcardInfo.entranceLinkTwo
        this.ruleForm.guidance_two = vcardInfo.guidanceTwo
        this.ruleForm.program_id_two = vcardInfo.programIdTwo
        this.ruleForm.program_page_two = vcardInfo.programPageTwo

        this.ruleForm.is_custom_three = vcardInfo.isCustomThree
        this.ruleForm.entrance_name_three = vcardInfo.entranceNameThree
        this.ruleForm.entrance_link_three = vcardInfo.entranceLinkThree
        this.ruleForm.guidance_three = vcardInfo.guidanceThree
        this.ruleForm.program_id_three = vcardInfo.programIdThree
        this.ruleForm.program_page_three = vcardInfo.programPageThree

        this.ruleForm.is_custom_four = vcardInfo.isCustomFour
        this.ruleForm.entrance_name_four = vcardInfo.entranceNameFour
        this.ruleForm.entrance_link_four = vcardInfo.entranceLinkFour
        this.ruleForm.guidance_four = vcardInfo.guidanceFour
        this.ruleForm.program_id_four = vcardInfo.programIdFour
        this.ruleForm.program_page_four = vcardInfo.programPageFour
        this.ruleForm.vcard_send_type=vcardInfo.vcardSendType
        this.ruleForm.vcard_send_url=vcardInfo.vcardSendUrl
        if(vcardInfo.vcardSendCoupons){//赠送卡券
          let vcardSendCouponsList = JSON.parse(vcardInfo.vcardSendCoupons);
          let gift_i = 0;
          let time_i = 0;
          vcardSendCouponsList.forEach(item=>{
            if(item.couType === 0) {//优惠券
              this.coupon = true;
              if(gift_i === 0) {
                this.arrayGiftData = [];
              }
              this.arrayGiftData.push({
                giftOptions: [],
                recSurplus: item.recSurplus,
                type: item.couId
              })
              gift_i++;
            } else {
              this.timesCard = true;
              if(gift_i === 0) {
                this.arrayTcountData = [];
              }
              this.arrayTcountData.push({
                giftOptions: [],
                recSurplus: item.recSurplus,
                type: item.couId
              })
              time_i++;
            }
          });
          /*if(haveGift) {
            this.arrayGiftData = delete this.arrayGiftData[0];
          }
          if(haveTime) {
            this.arrayTcountData = delete this.arrayTcountData[0];
          }*/
          /*this.getCoupons(1)
          let coupons=vcardInfo.vcardSendCoupons.split("&")
          coupons.forEach(o=>{
            this.ruleForm.vcard_send_coupons.unshift(o.split("=")[1])
          })*/
        }
        //this.ruleForm.vcard_send_coupons=vcardInfo.vcardSendCoupons
        this.getListSyCoupons();
        this.isLoading = false;
      });
    },
    getFileNameByPath(filePath){//根据文件全路径截取文件名
      const spliceLength = filePath.lastIndexOf("/");
      let fileName = filePath.slice(spliceLength + 1);
      return fileName;
    },
    handleChange(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      // .jpg,.jpeg,.png,.pdf,.PDF
      const extension1 = testmsg === 'jpg'
      const extension2 = testmsg === 'jpeg'
      const extension3 = testmsg === 'png'
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!extension1 && !extension2 && !extension3) {
        this.$message({
          message: '上传的图片只能是.jpg,.jpeg,.png格式!',
          type: 'warning'
        })
        this.fileList = [];
      } else if (!isLt1M) {
        this.$message({
          message: '上传的图片大小不能超过1M!',
          type: 'warning'
        })
        this.fileList = [];
      } else {
        this.objClass.upLoadHide = true;//上传图片后置upLoadHide为真，隐藏上传框
        this.objClass.upLoadShow = false;
        this.dialogImageUrl = file.url;
        this.ruleForm.vcard_log = file.raw;
      }
    },
    handleRemove(file, fileList) {
      this.objClass.upLoadShow = true;//删除图片后显示上传框
      this.objClass.upLoadHide = false;
      this.dialogImageUrl = '';
      this.ruleForm.vcard_log = null;
    },
    // 点击预览图的放大按钮后会触发handlePictureCardPreview
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange2(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      // .jpg,.jpeg,.png,.pdf,.PDF
      const extension1 = testmsg === 'jpg'
      const extension2 = testmsg === 'jpeg'
      const extension3 = testmsg === 'png'
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!extension1 && !extension2 && !extension3) {
        this.$message({
          message: '上传的图片只能是.jpg,.jpeg,.png格式!',
          type: 'warning'
        })
        this.fileList2 = [];
      } else if (!isLt2M) {
        this.$message({
          message: '上传的图片大小不能超过2M!',
          type: 'warning'
        })
        this.fileList2 = [];
      } else {
        this.objClass2.upLoadHide = true;//上传图片后置upLoadHide为真，隐藏上传框
        this.objClass2.upLoadShow = false;
        this.dialogImageUrl2 = file.url;
        this.ruleForm.vcard_back_img = file.raw;
      }
    },
    handleRemove2(file, fileList) {
      this.objClass2.upLoadShow = true;//删除图片后显示上传框
      this.objClass2.upLoadHide = false;
      this.dialogImageUrl2 = '';
      this.ruleForm.vcard_back_img = null;
    },
    // 点击预览图的放大按钮后会触发handlePictureCardPreview
    handlePictureCardPreview2(file) {
      this.dialogImageUrl2 = file.url;
      this.dialogVisible2 = true;
    },
    bgColorChange(val){
      let color = "";
      switch(val) {
        case 'Color010':
          color = '#63b359';
          break;
        case 'Color020':
          color = '#2c9f67';
          break;
        case 'Color030':
          color = '#509fc9';
          break;
        case 'Color040':
          color = '#5885cf';
          break;
        case 'Color050':
          color = '#9062c0';
          break;
        case 'Color060':
          color = '#d09a45';
          break;
        case 'Color070':
          color = '#e4b138';
          break;
        case 'Color080':
          color = '#ee903c';
          break;
        case 'Color081':
          color = '#f08500';
          break;
        case 'Color082':
          color = '#a9d92d';
          break;
        case 'Color090':
          color = '#dd6549';
          break;
        case 'Color100':
          color = '#cc463d';
          break;
        case 'Color101':
          color = '#cf3e36';
          break;
        case 'Color102':
          color = '#5E6671';
          break;
      }
      this.bgColorGradient = '-webkit-linear-gradient(' + this.lightenDarkenColor(color, 40) + ' 0, ' + color + ' 100%';
    },
    lightenDarkenColor(col, amt) {//获取比col颜色更浅或更深的颜色（通过正负值控制）
      var usePound = false;
      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }
      var num = parseInt(col,16);
      var r = (num >> 16) + amt;
      if (r > 255) r = 255;
      else if  (r < 0) r = 0;
      var b = ((num >> 8) & 0x00FF) + amt;
      if (b > 255) b = 255;
      else if  (b < 0) b = 0;
      var g = (num & 0x0000FF) + amt;
      if (g > 255) g = 255;
      else if (g < 0) g = 0;
      return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true;
          let vcardSendCoupons = []; //赠送的卡卷
          if(this.ruleForm.vcard_send_type === 1) {//勾选了赠送卡券
            if(this.timesCard) {//勾选了赠送次卡
              this.arrayTcountData.forEach((res) => {
                this.arrayTcountData.tcountOptions.forEach((data) => {
                  if (res.type === data.cou_id) {
                    vcardSendCoupons.push({
                      couBatch: data.cou_batch,
                      recSurplus: res.recSurplus,
                      couType: data.cou_type,
                      couId: data.cou_id
                    });
                  }
                });
              });
            }
            if(this.coupon) {//勾选了赠送优惠券
              this.arrayGiftData.forEach((res) => {
                this.arrayGiftData.giftOptions.forEach((data) => {
                  if (res.type === data.cou_id) {
                    vcardSendCoupons.push({
                      couBatch: data.cou_batch,//卡券批次
                      recSurplus: res.recSurplus,//数量
                      couType: data.cou_type,
                      couId: data.cou_id
                    });
                  }
                });
              });
            }
            if(vcardSendCoupons.length === 0) {
              this.$notify({
                title: "提示信息",
                message: "请选择代金卷或计次卡",
                type: "error",
                position: "bottom-right",
              });
              this.isLoading = false;
              return false;
            }
          }

          let formData = new FormData();
          formData.append("vcard_id", this.ruleForm.editId);
          formData.append("vcard_name", this.ruleForm.vcard_name);
          formData.append("vcard_title", this.ruleForm.vcard_title);
          formData.append("vcard_log", this.ruleForm.vcard_log);
          formData.append("vcard_back_img", this.ruleForm.vcard_back_img);
          formData.append("vcard_back_color", this.ruleForm.vcard_back_color);
          formData.append("vcard_type", this.ruleForm.vcard_type);
          formData.append("vcard_column", this.ruleForm.vcard_column);
          formData.append("vcard_vil", this.ruleForm.vcard_vil);
          formData.append("vcard_avi_time", this.ruleForm.vcard_avi_time);
          formData.append("vcard_disc", this.ruleForm.vcard_disc);
          formData.append("vcard_init_bonus", this.ruleForm.vcard_init_bonus);
          formData.append("vcard_ticket_consume", this.ruleForm.vcard_ticket_consume);
          formData.append("vcard_ticket_give", this.ruleForm.vcard_ticket_give);
          formData.append("vcard_in_part", this.ruleForm.vcard_in_part);
          formData.append("vcard_use", this.ruleForm.vcard_use);
          formData.append("vcard_phone", this.ruleForm.vcard_phone);
          formData.append("vcard_service", this.ruleForm.vcard_service);
          formData.append("vcard_send_type",this.ruleForm.vcard_send_type)
          formData.append("vcard_send_url",this.ruleForm.vcard_send_url);
          formData.append("vcard_send_coupons",JSON.stringify(vcardSendCoupons));
          let required_form = [];
          let optional_form = [];
          for(let i = 0;i < this.ruleForm.vcard_common_field.length;i++) {
            if(this.ruleForm.vcard_common_field[i] === 'USER_FORM_INFO_FLAG_MOBILE') {
              if(this.isRequired.phone === true) {
                required_form.push(this.ruleForm.vcard_common_field[i]);
              } else {
                optional_form.push(this.ruleForm.vcard_common_field[i]);
              }
            } else if(this.ruleForm.vcard_common_field[i] === 'USER_FORM_INFO_FLAG_SEX') {
              if(this.isRequired.sex === true) {
                required_form.push(this.ruleForm.vcard_common_field[i]);
              } else {
                optional_form.push(this.ruleForm.vcard_common_field[i]);
              }
            } else if(this.ruleForm.vcard_common_field[i] === 'USER_FORM_INFO_FLAG_NAME') {
              if(this.isRequired.name === true) {
                required_form.push(this.ruleForm.vcard_common_field[i]);
              } else {
                optional_form.push(this.ruleForm.vcard_common_field[i]);
              }
            } else if(this.ruleForm.vcard_common_field[i] === 'USER_FORM_INFO_FLAG_BIRTHDAY') {
              if(this.isRequired.birthday === true) {
                required_form.push(this.ruleForm.vcard_common_field[i]);
              } else {
                optional_form.push(this.ruleForm.vcard_common_field[i]);
              }
            } else if(this.ruleForm.vcard_common_field[i] === 'USER_FORM_INFO_FLAG_IDCARD') {
              if(this.isRequired.idCard === true) {
                required_form.push(this.ruleForm.vcard_common_field[i]);
              } else {
                optional_form.push(this.ruleForm.vcard_common_field[i]);
              }
            } else if(this.ruleForm.vcard_common_field[i] === 'USER_FORM_INFO_FLAG_EMAIL') {
              if(this.isRequired.email === true) {
                required_form.push(this.ruleForm.vcard_common_field[i]);
              } else {
                optional_form.push(this.ruleForm.vcard_common_field[i]);
              }
            } else if(this.ruleForm.vcard_common_field[i] === 'USER_FORM_INFO_FLAG_LOCATION') {
              if(this.isRequired.address === true) {
                required_form.push(this.ruleForm.vcard_common_field[i]);
              } else {
                optional_form.push(this.ruleForm.vcard_common_field[i]);
              }
            } else if(this.ruleForm.vcard_common_field[i] === 'USER_FORM_INFO_FLAG_EDUCATION_BACKGRO') {
              if(this.isRequired.education === true) {
                required_form.push(this.ruleForm.vcard_common_field[i]);
              } else {
                optional_form.push(this.ruleForm.vcard_common_field[i]);
              }
            } else if(this.ruleForm.vcard_common_field[i] === 'USER_FORM_INFO_FLAG_INDUSTRY') {
              if(this.isRequired.industry === true) {
                required_form.push(this.ruleForm.vcard_common_field[i]);
              } else {
                optional_form.push(this.ruleForm.vcard_common_field[i]);
              }
            } else if(this.ruleForm.vcard_common_field[i] === 'USER_FORM_INFO_FLAG_INCOME') {
              if(this.isRequired.income === true) {
                required_form.push(this.ruleForm.vcard_common_field[i]);
              } else {
                optional_form.push(this.ruleForm.vcard_common_field[i]);
              }
            } else if(this.ruleForm.vcard_common_field[i] === 'USER_FORM_INFO_FLAG_HABIT') {
              if(this.isRequired.interest === true) {
                required_form.push(this.ruleForm.vcard_common_field[i]);
              } else {
                optional_form.push(this.ruleForm.vcard_common_field[i]);
              }
            }
          }
          formData.append("required_form", required_form);
          formData.append("optional_form", optional_form);
          formData.append("vcard_is_share", this.ruleForm.vcard_is_share);
          formData.append("vcard_is_activate", this.ruleForm.vcard_is_activate);
          formData.append("symd", this.ruleForm.symd);

          formData.append("is_custom_one", this.ruleForm.is_custom_one);
          formData.append("entrance_name_one", this.ruleForm.entrance_name_one);
          formData.append("entrance_link_one", this.ruleForm.entrance_link_one);
          formData.append("guidance_one", this.ruleForm.guidance_one);
          formData.append("program_id_one", this.ruleForm.program_id_one);
          formData.append("program_page_one", this.ruleForm.program_page_one);

          formData.append("is_custom_two", this.ruleForm.is_custom_two);
          formData.append("entrance_name_two", this.ruleForm.entrance_name_two);
          formData.append("entrance_link_two", this.ruleForm.entrance_link_two);
          formData.append("guidance_two", this.ruleForm.guidance_two);
          formData.append("program_id_two", this.ruleForm.program_id_two);
          formData.append("program_page_two", this.ruleForm.program_page_two);

          formData.append("is_custom_three", this.ruleForm.is_custom_three);
          formData.append("entrance_name_three", this.ruleForm.entrance_name_three);
          formData.append("entrance_link_three", this.ruleForm.entrance_link_three);
          formData.append("guidance_three", this.ruleForm.guidance_three);
          formData.append("program_id_three", this.ruleForm.program_id_three);
          formData.append("program_page_three", this.ruleForm.program_page_three);

          formData.append("is_custom_four", this.ruleForm.is_custom_four);
          formData.append("entrance_name_four", this.ruleForm.entrance_name_four);
          formData.append("entrance_link_four", this.ruleForm.entrance_link_four);
          formData.append("guidance_four", this.ruleForm.guidance_four);
          formData.append("program_id_four", this.ruleForm.program_id_four);
          formData.append("meid",this.ruleForm.shanghu)
          formData.append("program_page_four", this.ruleForm.program_page_four);
          api.changeVipInfo(this.isAdd ? "add" : "edit", formData).then(res => {
            this.isLoading = false;
            if (res.data.code === 200) {
              this.$notify({
                title: "操作成功",
                message: "",
                position: "bottom-right",
                type: "success",
              });
              this.$router.go(-1)
            }
          }).catch(e => {
            this.isLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    initFieldInfo(fieldList, isRequired) {
      if(fieldList == null) {
        return;
      }
      for(let i = 0;i < fieldList.length;i++) {
        if(fieldList[i] === 'USER_FORM_INFO_FLAG_MOBILE') {
          this.isRequired.phone = isRequired;
        } else if(fieldList[i] === 'USER_FORM_INFO_FLAG_SEX') {
          this.isRequired.sex = isRequired;
        } else if(fieldList[i] === 'USER_FORM_INFO_FLAG_NAME') {
          this.isRequired.name = isRequired;
        } else if(fieldList[i] === 'USER_FORM_INFO_FLAG_BIRTHDAY') {
          this.isRequired.birthday = isRequired;
        } else if(fieldList[i] === 'USER_FORM_INFO_FLAG_IDCARD') {
          this.isRequired.idCard = isRequired;
        } else if(fieldList[i] === 'USER_FORM_INFO_FLAG_EMAIL') {
          this.isRequired.email = isRequired;
        } else if(fieldList[i] === 'USER_FORM_INFO_FLAG_LOCATION') {
          this.isRequired.address = isRequired;
        } else if(fieldList[i] === 'USER_FORM_INFO_FLAG_EDUCATION_BACKGRO') {
          this.isRequired.education = isRequired;
        } else if(fieldList[i] === 'USER_FORM_INFO_FLAG_INDUSTRY') {
          this.isRequired.industry = isRequired;
        } else if(fieldList[i] === 'USER_FORM_INFO_FLAG_INCOME') {
          this.isRequired.income = isRequired;
        } else if(fieldList[i] === 'USER_FORM_INFO_FLAG_HABIT') {
          this.isRequired.interest = isRequired;
        }
      }
    },
    scrollDs : function (event) {
      // 页面指定了DTD，即指定了DOCTYPE时，使用document.documentElement。
      // 页面没有DTD，即没指定DOCTYPE时，使用document.body。
      let top = event.target.scrollTop;
      if(top >= 40){
        this.cardMarginTop = {
          marginTop: "-40px",
          transition: "all 400ms"
      };
    } else {
      this.cardMarginTop = {
        transition: "all 400ms"
      };
    }
  },
}
};
</script>

<style lang="scss" scoped>
.createTimeInputWidth {
  width: 70%;
}
.dis_card_code {
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: #edf0f5;
  right: 30px;
  top: 22px;
}
.discode {
  margin-top: 10px;
  width: 85%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #c4cdd6;
}
.radio_type_group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.radio-ctrl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 15px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  .pic {
    margin-top: 5px;
    /*background-color: skyblue;*/
    width: 380px;
    height: 200px;
    border-radius: 10px;
    /*display: flex;*/
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .things {
    margin-top: 5px;
    width: 95%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .center-btn{
   /* display: block;*/
    margin-top: 10px;
    border: 1px solid #3f8f6c;
    width:140px;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
  }
  .others {
    margin-bottom: 15px;
    font-size: 15px;
    margin-top: 5px;
    width: 95%;
    /*height: 40%;*/
    line-height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .card_infos {
      height: 100%;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #c4cdd6;
    }
  }
}
.bg-purple-light {
  background: #e5e9f2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 15px;
}
.grid-content {
  border-radius: 4px;
  /*min-height: 85vh;
  height: 85%;*/
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.member_card .logo_area {
  height: 170px;
  margin: 0;
  padding: 18px 20px 12px 20px;
  text-align: left;
  line-height: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-shadow: 1px 1px 3px #666;
}
.logo_area {
  margin-bottom: -8px;
  line-height: 42px;
  color: #FFF;
  color: rgba(255,255,255,0.9);
}
.member_card .vip_logo {
  position: absolute;
  width: 38px;
  height: 38px;
  top: 21px;
  left: 20px;
}
.vip_logo {
  display: block;
  width: 38px;
  height: 38px;
  padding-top: 0;
  margin-right: 7px;
  border-radius: 22px;
  -moz-border-radius: 22px;
  -webkit-border-radius: 22px;
  border: 1px solid #d3d3d3;
}
.vip_logo {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .05em;
  line-height: 60px;
  text-transform: uppercase;
}
.vip_logo img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  -moz-border-radius: inherit;
  -webkit-border-radius: inherit;
}
  .card_right {
    color: #80878c;
  }
  .msg_card_cell.member_extend_area {
    margin: 0;
    padding: 0;
    height: 53px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .msg_card_cell.member_extend_area li {
    width: 115px;
    height: 36px;
    border-left: 1px solid #c4cdd6;
    text-align: center;
    line-height: 14px;
    list-style: none;
    display: inline-block;
  }
  .msg_card_cell.member_extend_area li:first-child {
    border-left: 0;
  }
  .msg_card_cell.member_extend_area li span {
    margin-top: 1px;
    color: #676a6c;
    display: inline-block;
    vertical-align: top;
    font-size: 13px;
  }
  .msg_card_cell.member_extend_area li p {
    margin-top: 10px;
    line-height: inherit;
    cursor: default;
    font-size: 16px;
    color: #63b359;
  }
</style>
<style>
  /*当upLoadHide为true时，启用如下样式，即上传框的样式，若为false则不启用该样式*/
  .vip-form .upLoadHide .el-upload {
    display: none;
  }
  .tim-select-color-radio .el-radio__inner {
     width: 30px;
     height: 30px;
   }
  .tim-select-color-radio .color010 .el-radio__inner {
    background-color: #63b359;
  }
  .tim-select-color-radio .color020 .el-radio__inner {
    background-color: #2c9f67;
  }
  .tim-select-color-radio .color030 .el-radio__inner {
    background-color: #509fc9;
  }
  .tim-select-color-radio .color040 .el-radio__inner {
    background-color: #5885cf;
  }
  .tim-select-color-radio .color050 .el-radio__inner {
    background-color: #9062c0;
  }
  .tim-select-color-radio .color060 .el-radio__inner {
    background-color: #d09a45;
  }
  .tim-select-color-radio .color070 .el-radio__inner {
    background-color: #e4b138;
  }
  .tim-select-color-radio .color080 .el-radio__inner {
    background-color: #ee903c;
  }
  .tim-select-color-radio .color081 .el-radio__inner {
    background-color: #f08500;
  }
  .tim-select-color-radio .color082 .el-radio__inner {
    background-color: #a9d92d;
  }
  .tim-select-color-radio .color090 .el-radio__inner {
    background-color: #dd6549;
  }
  .tim-select-color-radio .color100 .el-radio__inner {
    background-color: #cc463d;
  }
  .tim-select-color-radio .color101 .el-radio__inner {
    background-color: #cf3e36;
  }
  .tim-select-color-radio .color102 .el-radio__inner {
    background-color: #5E6671;
  }
  .tim-select-color-radio .el-radio {
    margin-right: 0;
  }
  .tim-select-color-radio .el-radio, .el-radio__inner, .el-radio__input {
    position: inherit;/*去掉选中时中间的白点*/
  }
  .tim-select-color-radio .is-checked .el-radio__inner {
    background-image: url('../../assets/colorSelectActive.png');
    background-repeat: no-repeat;
    background-size: 94% 94%;
    background-position: center center;
  }
  .tim-select-color-radio .el-radio__input.is-checked .el-radio__inner {
    border-color: #FFFFFF;
  }
  .kkxx_switch {
    margin-top: -9px;
  }
  .kkxx_switch .el-switch__label.is-active {
    color: #F56C6C;
  }
</style>
